<template>
  <div>
    <div class="navbox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="-1">
          <span slot="label">
            审核
            <i v-if="countRoute > 0" style="color:red">({{countRoute}})</i>
          </span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">
            列表
            <i v-if="activeName==2">({{total}})</i>
          </span>
        </el-tab-pane>
        <el-tab-pane label="个人" name="0"></el-tab-pane>
      </el-tabs>
      <div style="padding-bottom:10px;margin-left:120px">
        <el-select v-model="value" placeholder="请选择" @change="handleChange">
          <el-option
            v-for="item in provinces"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
        <!-- <el-cascader v-model="value" :options="provinces" @change="handleChange" size="small"></el-cascader> -->
      </div>
    </div>

    <el-table :data="SightRoutes" style="width: 100%" size="small">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="起点" prop="startName"></el-table-column>
      <el-table-column label="天数" prop="duration"></el-table-column>
      <el-table-column label="更新" prop="updatedTime"></el-table-column>
      <el-table-column label="查看">
        <template slot-scope="scope">
          <el-button @click="toRouteDetail(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>
  
  <script>
import { getAdminRoutes, getCityList } from "@/api/api";
export default {
  data() {
    return {
      SightRoutes: [],
      page: 1,
      count: 20,
      total: null,
      activeName: "2",
      adcode: "100000",
      name: "",
      provinces: [],
      value: "全部地区"
    };
  },
  mounted() {
    if (this.countRoute > 0) {
      this.activeName = "-1";
    }
    this.getAdminSightRoutesFn();
    this.getCityListFn();
  },
  computed: {
    countRoute() {
      return this.$store.getters.getFirstAuth.route;
    }
  },
  methods: {
    getAdminSightRoutesFn() {
      const that = this;
      getAdminRoutes({
        page: that.page,
        state: that.activeName,
        count: that.count,
        adcode: that.adcode
      }).then(res => {
        if (res.result) {
          that.SightRoutes = res.data.routes;
          that.total = res.data.total;
        }
      });
    },

    getCityListFn() {
      const that = this;
      getCityList({
        type: "province"
      }).then(res => {
        that.provinces = res.data;
        that.provinces.unshift({
          value: 100000,
          name: "全部地区"
        });
      });
    },
    toRouteDetail(data) {
      this.$router.push({
        path: "/sightroutes/routedetail",
        query: { id: data.id }
      });
    },

    handleClick() {
      this.adcode = 100000;
      this.value = "全部地区";
      this.getAdminSightRoutesFn();
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getAdminSightRoutesFn();
    },

    handleChange(value) {
      this.adcode = value;
      this.activeName = "2";
      this.getAdminSightRoutesFn();
    }
  }
};
</script>
  
<style scoped>
.navbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}
</style>